<template>
  <div class="table-responsive adaptive-table" v-if="list.length > 0">
    <table class="table table-striped table-hover adaptive-table" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in dataList" :key="row.id">
          <td :data-label="$t('forms.billing.rows.requestCount')">
            {{ row.messages }} / {{ row.package_messages }}
          </td>
          <td :data-label="$t('forms.billing.rows.requestType')">
            {{ $t(`forms.billing.rows.types.${row.type}`) }}
          </td>
          <td :data-label="$t('forms.billing.rows.dateCreate')">
            {{ humanDateTime(row.create_date, $i18n.locale) }}
          </td>
          <td :data-label="$t('forms.billing.rows.expiryDate')">
              {{ humanDate(row.expire_date, $i18n.locale) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useHumanDate } from '@/application/composables/humanDate.js'
import { onMounted, ref } from 'vue'
import { getLocalizedOrValue } from '@/application/utils/localization'

export default {
  name: 'ScheduledCampaignsTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { humanDate, humanDateTime } = useHumanDate()
    const dataList = ref(props.list)
    const fields = [
      'forms.billing.rows.requestCount',
      'forms.billing.rows.requestType',
      'forms.billing.rows.dateCreate',
      'forms.billing.rows.expiryDate',
    ]

    onMounted(() => {
      window.$(function() {
        window.$('[data-toggle="tooltip"]').tooltip()
      })
    })

    return {
      humanDate,
      humanDateTime,
      fields,
      dataList,
      getLocalizedOrValue,
    }
  },
}
</script>
